import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../Views/HomePage.vue';
import DetailsHomePage from '../Views/DetailsHomePage.vue';
import PrenotazioniInfoPage from '../Views/PrenotazioniInfoPage.vue';
import CorsePage from '../Views/CorsePage.vue';
import CorseFuturePage from '../Views/CorseFuturePage.vue';
import CorsePassatePage from '../Views/CorsePassatePage.vue';
import ContenutiPage from '../Views/ContenutiPage.vue';
//import AddCustomerPage from '../Views/AddCustomerPage.vue';
import AddCarPage from '../Views/AddCarPage.vue';
import CustomerPage from '../Views/CustomerPage.vue';
import CustomerAliveDetailPage from '../Views/CustomerAliveDetailPage.vue';
import LoginPage from '../Views/LoginPage.vue';
import TariffePage from '../Views/TariffePage.vue';
import ErrorPage from '../Views/ErrorPage.vue';
import AddCustomerEmployeePage from '../Views/AddCustomerEmployeePage.vue';
import ProfilePage from '../Views/ProfilePage.vue'
//import HomeVenditorePage from '../Views/HomeVenditorePage.vue'
import ContenutiVenditorePage from '../Views/ContenutiVenditorePage.vue';
import ResetPassword from '../Views/ResetPasswordPage.vue';
import LavoriAggiuntiviPage from '@/Views/LavoriAggiuntiviPage.vue';
import ModificaDataPage from '@/Views/ModificaDataPage.vue';
import JourneyPage from '@/Views/JourneyPage.vue';
import JourneyCalendarPage from '@/Views/JourneyCalendarPage.vue';
import DetailsJourneyPage from '@/Views/DetailsJourneyPage.vue';
import { isLoggedStore } from '@/store/logged';
const routes = [

  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/booking_attivo/:id',
    name: 'DetailsHome',
    component: DetailsHomePage,
  },
  {
    path: '/prenotazioni-info',
    name: 'PrenotazioniInfo',
    component: PrenotazioniInfoPage,
  },
  {
    path: '/corse',
    name: 'Corse',
    component: CorsePage,
  },
  {
    path: '/tasks/:id',
    name: 'LavoriAggiuntivi',
    component: LavoriAggiuntiviPage,
  },
  {
    path: '/update-date/:id',
    name: 'ModificaDataPage',
    component: ModificaDataPage,
  },
  {
    path: '/corse/corse_passate',
    name: 'CorsePassate',
    component: CorsePassatePage,
  },
  {
    path: '/corse/corse_future',
    name: 'CorseFuture',
    component: CorseFuturePage,
  },
  {
    path: '/contenuti',
    component: ContenutiPage,
  },
  {
    path: '/addcustomer_employee',
    name: 'AddCustomerEmployee',
    component: AddCustomerEmployeePage,
  },
  /* {
    path: '/addcustomer',
    name: 'AddCustomer',
    component: AddCustomerPage,
  }, */
  {
    path: '/customer',
    name: 'Customer',
    component: CustomerPage,
  },
  {
    path: '/add-car/:id',
    name: 'AddCarPage',
    component: AddCarPage,
  },
  {
    path: '/details/customerAlive/:id',
    name: 'CustomerAliveDetail',
    component: CustomerAliveDetailPage,
  },
  /* {
    path: '/home',
    name: 'HomeVenditore',
    component: HomeVenditorePage,
  }, */
  {
    path: '/contenuti-vendor',
    name: 'ContenutiVenditore',
    component: ContenutiVenditorePage,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfilePage,
  },
  {
    path: '/tariffe',
    name: 'Tariffe',
    component: TariffePage,
  },
  {
    path: '/journey/:date',
    name: 'JourneyPage',
    component: JourneyPage,
    props: true,
  },
  {
    path: '/calendar',
    name: 'JourneyCalendarPage',
    component: JourneyCalendarPage,
  },
  {
    path: '/detail-journey/:date/:id',
    name: 'DetailsJourneyPage',
    component: DetailsJourneyPage,
    props: true,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  const store = isLoggedStore();
  if (
    // make sure the user is authenticated
   store.isLogged == false &&
    // ❗️ Avoid an infinite redirect
    ( to.name !=='Login')

  ) {
    if(to.name !=='ResetPassword')

    {console.log("not auth")
    // redirect the user to the login page
    return { name: 'Login' };}
  }
  

  if (
    to.path == '/login' || to.name== '/reset-password'
  ) {
    store.viewMenu = false;
  } else {
    store.viewMenu = true;
  }

  if (store.role === 'salesvendor') 

  {
      if
    (
      to.name === 'DetailsHome'
    || to.name === 'Prenotazioni'
    || to.name === 'Corse'
    || to.name === 'CorseAttive'
    || to.name === 'CorsePassate'
    || to.name === 'CorseFuture'

    )
    return {name: 'Error'}
  }


  if (store.role === 'employee')
  {if (to.name === 'AddCustomer') return {name: 'Error'}}
});

export default router;
