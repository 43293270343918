<template>
  <div class="background">
    <h1 style="text-align: center">CREA UN NUOVO PERCORSO</h1>

    <div style="margin-top: 2%">
      <el-form
        :inline="true"
        :model="formData"
        :rules="formRuleData"
        ref="dataForm"
        class="journey-form"
      >
        <el-form-item label="Seleziona una data" prop="journeyDate">
          <el-date-picker
            v-model="formData.journeyDate"
            format="DD/MM/YYYY"
            value-format="YYYY-MM-DD"
            type="date"
            :size="size"
            placeholder="Seleziona una data"
            @change="validateDate"
          />
        </el-form-item>

        <el-alert
          v-if="dateError"
          title="Impossibile selezionare questa data. Il numero massimo di viaggi è stato raggiunto."
          type="error"
          show-icon
          class="error-message"
        />
      </el-form>
    </div>
    <el-card class="journey-card" shadow="hover">
      <h3>Prima Tappa</h3>
      <div>
        <el-form
          :inline="true"
          ref="journeyFormFirst"
          :model="routes[0]"
          :rules="formRule"
        >
          <div class="route-row">
            <div class="route-item">
              <el-form-item label="Punto di Partenza" prop="startPoint">
                <el-cascader
                  v-model="routes[0].startPoint"
                  placeholder="Seleziona"
                  @change="
                    (selectedValue) => handleChangeStartPoint(selectedValue, 0)
                  "
                  :options="headquartersCascader"
                  filterable
                  clearable
                  :class="{ highlight: highlight }"
                  :props="{ value: 'point' }"
                  :emit-path="false"
                />
              </el-form-item>
            </div>

            <div class="route-item">
              <el-form-item label="Orario di Partenza" prop="dateStart">
                <!--  <div class="time-picker-wrapper">
                  <div class="time-picker-icon">
                    <i class="el-icon-time"></i> 
                  </div>
                  <input
                    type="time"
                    class="time-picker-input-with-icon"
                    v-model="routes[0].dateStart"
                    @change="(event) => calculateArrivalTime(routes[0], 0)"
                  />
                </div> -->

                <div class="time-picker-wrapper">
                  <div class="time-picker-icon">
                    <i class="el-icon-time"></i>
                  </div>
                  <input
                    type="time"
                    class="time-picker-input-with-icon"
                    v-model="routes[0].dateStart"
                    placeholder="Seleziona orario"
                    @change="(event) => calculateArrivalTime(routes[0], 0)"
                    list="time-options"
                  />
                  <datalist id="time-options">
                    <option
                      v-for="(time, index) in validTimes(0)"
                      :key="index"
                      :value="time"
                    >
                      {{ time }}
                    </option>
                  </datalist>
                </div>
              </el-form-item>
            </div>

            <div class="route-item">
              <el-form-item label="Punto di Arrivo" prop="endPoint">
                <el-cascader
                  v-model="routes[0].endPoint"
                  placeholder="Seleziona"
                  @change="
                    (selectedValue) => handleChangeEndPoint(selectedValue, 0)
                  "
                  :options="headquartersCascader"
                  filterable
                  clearable
                  :class="{ highlight: highlight }"
                  :props="{ value: 'point' }"
                  :emit-path="false"
                />
              </el-form-item>
            </div>

            <div class="route-item">
              <el-form-item label="Orario di Arrivo" prop="dateEnd">
                <el-time-picker
                  v-model="routes[0].dateEnd"
                  placeholder="Calcolato automaticamente"
                  arrow-control
                  format="HH:mm"
                  value-format="HH:mm"
                  disabled
                />
              </el-form-item>
            </div>

            <div class="action-buttons">
              <el-button type="success" @click="addRoute(0)" circle>
                <el-icon><CirclePlusFilled /></el-icon>
              </el-button>
            </div>
          </div>

          <div class="route-row">
            <div class="route-item-description">
              <el-descriptions class="margin-top" :column="4" border>
                <el-descriptions-item label="Targa">
                  <el-form-item prop="targa">
                    <el-input
                      v-model="routes[0].targa"
                      placeholder="Targa o telaio"
                      class="centered-description-item"
                    />
                  </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="Marca">
                  <el-form-item prop="marca">
                    <el-input
                      v-model="routes[0].marca"
                      placeholder="Marca"
                      class="centered-description-item"
                    />
                  </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="Modello">
                  <el-form-item prop="modello">
                    <el-input
                      v-model="routes[0].modello"
                      placeholder="Modello"
                      class="centered-description-item"
                    />
                  </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="Dettagli">
                  <el-form-item prop="details">
                    <el-input
                      v-model="routes[0].details"
                      placeholder="Note particolari"
                      class="centered-description-item"
                    />
                  </el-form-item>
                </el-descriptions-item>
              </el-descriptions>
            </div>

            <!-- Switch Movimento -->
            <el-form-item>
              <el-switch
                v-model="routes[0].movement"
                active-text="Auto da spostare"
                inactive-text="Auto per il driver"
              />
            </el-form-item>
          </div>
        </el-form>
      </div>
    </el-card>

    <el-card
      v-for="(route, index) in routes.slice(1)"
      :key="index"
      class="journey-card"
      shadow="hover"
    >
      <h3 style="margin-bottom: 10px">Tappa {{ index + 1 }}</h3>
      <el-form
        :inline="true"
        :ref="(el) => (journeyForm[`journeyForm-${index}`] = el)"
        :model="route"
        :rules="formRule"
      >
        <div class="route-row">
          <div class="route-item">
            <el-form-item label="Punto di Partenza" prop="startPoint">
              <el-input
                v-if="routes[index].endPoint == null"
                :value="''"
                disabled
              />
              <el-input
                v-else-if="routes[index].endPoint"
                :value="
                  routes[index].endPoint?.address +
                    ' ' +
                    routes[index].endPoint?.street_number +
                    ', ' +
                    routes[index].endPoint?.city || ''
                "
                disabled
              />
            </el-form-item>
          </div>

          <div class="route-item">
            <el-form-item label="Orario di Partenza" prop="dateStart">
              <!-- <el-time-picker
                v-model="route.dateStart"
                placeholder="Seleziona orario"
                arrow-control
                format="HH:mm"
                value-format="HH:mm"
                @change="calculateArrivalTime(route, index)"
                :disabled-hours="() => getDisabledHours(index)"
                :disabled-minutes="(hour) => getDisabledMinutes(index, hour)"
              /> -->

              <div class="time-picker-wrapper">
                <div class="time-picker-icon">
                  <i class="el-icon-time"></i>
                </div>
                <input
                  type="time"
                  class="time-picker-input-with-icon"
                  v-model="route.dateStart"
                  placeholder="Seleziona orario"
                  @change="(event) => calculateArrivalTime(route, index)"
                  list="time-options"
                />
                <datalist id="time-options">
                  <option
                    v-for="(time, index) in validTimes(index)"
                    :key="index"
                    :value="time"
                  >
                    {{ time }}
                  </option>
                </datalist>
              </div>
            </el-form-item>
          </div>

          <div class="route-item">
            <el-form-item label="Punto di Arrivo" prop="endPoint">
              <el-cascader
                v-model="route.endPoint"
                placeholder="Seleziona"
                @change="
                  (selectedValue) =>
                    handleChangeEndPoint(selectedValue, index + 1)
                "
                :options="headquartersCascader"
                filterable
                clearable
                :class="{ highlight: highlight }"
                :props="{ value: 'point' }"
                :emit-path="false"
              />
            </el-form-item>
          </div>

          <div class="route-item">
            <el-form-item label="Orario di Arrivo" prop="dateEnd">
              <el-time-picker
                v-model="route.dateEnd"
                placeholder="Calcolato automaticamente"
                arrow-control
                disabled
                format="HH:mm"
                value-format="HH:mm"
              />
            </el-form-item>
          </div>

          <div class="action-buttons">
            <el-button type="success" @click="addRoute(index+1)" circle>
              <el-icon><CirclePlusFilled /></el-icon>
            </el-button>
            <el-button type="danger" @click="removeRoute(index + 1)" circle>
              <el-icon><RemoveFilled /></el-icon>
            </el-button>
          </div>
        </div>
        <div class="route-row">
          <!-- Dettagli dell'Auto -->
          <div class="route-item-description">
            <el-descriptions class="margin-top" :column="4" border>
              <el-descriptions-item label="Targa">
                <el-form-item prop="targa">
                  <el-input
                    v-model="route.targa"
                    placeholder="Targa o telaio"
                    class="centered-description-item"
                  />
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item label="Marca">
                <el-form-item prop="marca">
                  <el-input
                    v-model="route.marca"
                    placeholder="Marca"
                    class="centered-description-item"
                  />
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="Modello">
                <el-form-item prop="modello">
                  <el-input
                    v-model="route.modello"
                    placeholder="Modello"
                    class="centered-description-item"
                  />
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="Dettagli">
                <el-form-item prop="details">
                  <el-input
                    v-model="route.details"
                    placeholder="Note particolari"
                    class="centered-description-item"
                  />
                </el-form-item>
              </el-descriptions-item>
            </el-descriptions>
          </div>

          <!-- Switch Movimento -->
          <div class="route-item">
            <el-switch
              v-model="route.movement"
              active-text="Auto da spostare"
              inactive-text="Auto per il driver"
            />
          </div>
        </div>
      </el-form>
    </el-card>

    <el-card class="journey-card" shadow="hover">
      <h3 style="margin-bottom: 10px">Ultima Tappa</h3>
      <el-form
        :inline="true"
        ref="journeyFormLast"
        :model="endRoute"
        :rules="formRule"
      >
        <div class="route-row">
          <div class="route-item">
            <el-form-item label="Punto di Partenza" prop="startPoint">
              <el-input
                v-if="routes[routes.length - 1].endPoint == null"
                :value="''"
                disabled
              />
              <el-input
                v-else-if="routes[routes.length - 1].endPoint"
                v-model="endRoute.startPoint"
                :value="
                  routes[routes.length - 1].endPoint?.address +
                    ' ' +
                    routes[routes.length - 1].endPoint?.street_number +
                    ', ' +
                    routes[routes.length - 1].endPoint?.city || ''
                "
                disabled
              />
            </el-form-item>
          </div>
          <el-form-item label="Orario di Partenza" prop="dateStart">
            <!--  <el-time-picker
              v-model="endRoute.dateStart"
              placeholder="Seleziona orario"
              arrow-control
              format="HH:mm"
              value-format="HH:mm"
              @change="calculateArrivalTime(endRoute, routes.length - 1)"
              :disabled-hours="() => getDisabledHours(routes.length - 1)"
              :disabled-minutes="
                (hour) => getDisabledMinutes(routes.length - 1, hour)
              "
            /> -->
            <div class="time-picker-wrapper">
              <div class="time-picker-icon">
                <i class="el-icon-time"></i>
              </div>
              <input
                type="time"
                class="time-picker-input-with-icon"
                v-model="endRoute.dateStart"
                placeholder="Seleziona orario"
                @change="
                  (event) => calculateArrivalTime(endRoute, routes.length - 1)
                "
                list="time-options"
              />
              <datalist id="time-options">
                <option
                  v-for="(time, index) in validTimes(routes.length - 1)"
                  :key="index"
                  :value="time"
                >
                  {{ time }}
                </option>
              </datalist>
            </div>
          </el-form-item>

          <el-form-item label="Punto di Arrivo" prop="endPoint">
            <el-input
              v-if="routes[routes.length - 1].startPoint == null"
              :value="''"
              disabled
            />
            <el-input
              v-else-if="routes[routes.length - 1].startPoint"
              v-model="endRoute.endPoint"
              :value="
                routes[0].startPoint?.address +
                  ' ' +
                  routes[0].startPoint?.street_number +
                  ', ' +
                  routes[0].startPoint?.city || ''
              "
              disabled
            />
          </el-form-item>

          <el-form-item label="Orario di Arrivo" prop="endDate">
            <el-time-picker
              v-model="endRoute.dateEnd"
              placeholder="Calcolato automaticamente"
              arrow-control
              disabled
              format="HH:mm"
              value-format="HH:mm"
            />
          </el-form-item>
        </div>
        <div class="route-row">
          <!-- Dettagli dell'Auto -->
          <div class="route-item-description">
            <el-descriptions class="margin-top" :column="4" border>
              <el-descriptions-item label="Targa">
                <el-form-item prop="targa">
                  <el-input
                    v-model="endRoute.targa"
                    placeholder="Targa o telaio"
                    class="centered-description-item"
                  />
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item label="Marca">
                <el-form-item prop="marca">
                  <el-input
                    v-model="endRoute.marca"
                    placeholder="Marca"
                    class="centered-description-item"
                  />
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="Modello">
                <el-form-item prop="modello">
                  <el-input
                    v-model="endRoute.modello"
                    placeholder="Modello"
                    class="centered-description-item"
                  />
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="Dettagli">
                <el-form-item prop="details">
                  <el-input
                    v-model="endRoute.details"
                    placeholder="Note particolari"
                    class="centered-description-item"
                  />
                </el-form-item>
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <!-- Switch Movimento -->
          <div class="route-item">
            <el-switch
              v-model="endRoute.movement"
              active-text="Auto da spostare"
              inactive-text="Auto per il driver"
            />
          </div>
        </div>
      </el-form>
    </el-card>

    <div v-if="routes.length > 0" class="summary-container">
      <el-card class="summary-card" shadow="hover">
        <div class="summary-content">
          <el-icon style="margin-right: 10px; color: #409eff">
            <Timer />
          </el-icon>
          <p class="total-hours">
            <strong>Totale Ore:</strong> {{ totalHours }}
          </p>
        </div>
      </el-card>
    </div>

    <div style="text-align: center; margin-top: 20px">
      <el-button type="success" size="large" @click="saveJourney">
        Salva Journey
      </el-button>
    </div>
  </div>
</template>

<script>
import { ref /* ,watch  */ } from 'vue';
import bookingAPI from '../services/bookingAPI';
import journeyAPI from '../services/journeyAPI';
import { isLoggedStore } from '../store/logged';
import { Delete } from '@element-plus/icons-vue';
import partnerAPI from '@/services/partnerAPI';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import dayjs from 'dayjs';

import { ElMessage } from 'element-plus';
export default {
  components: {},
  name: 'JourneyPage',

  setup() {
    const storeLogin = isLoggedStore();
    const headquartersCascader = ref([]);
    const headquarters = ref([]);
    const router = useRouter();
    const route = useRoute();
    const journeyDate = route.params.date ? ref(route.params.date) : ref(null);
    //const selectedDate = route.params.date;

    const routes = ref([
      {
        startPoint: null,
        endPoint: null,
        dateStart: '',
        dateEnd: '',
        details: '',
        movement: true,
        marca: '',
        modello: '',
        targa: '',
      },
    ]);
    const endRoute = ref({
      startPoint: null,
      endPoint: null,
      dateStart: null,
      dateEnd: '',
      details: '',
      movement: true,
      marca: '',
      modello: '',
      targa: '',
    });
    const loadHeadquarters = async () => {
      try {
        const response = await bookingAPI.getHeadquartersByEmployee(
          storeLogin.id
        );
        response.data[0].headquarters.forEach((headquarter) => {
          headquartersCascader.value.push({
            value: headquarter.id,
            label: `${headquarter.address} ${headquarter.street_number} ${headquarter.city}`,
            point: {
              address: headquarter.address,
              street_number: headquarter.street_number,
              cap: headquarter.cap,
              city: headquarter.city,
              province: headquarter.province,
              latitude: headquarter.latitude,
              longitude: headquarter.longitude,
            },
          });
          headquarters.value.push(headquarter);
        });
      } catch (error) {
        console.error(
          'Errore nel caricamento degli headquarters:',
          error.message
        );
      }
    };

    const addRoute = (index) => {
      const previousRoute = routes.value[index];
      const newRoute = {
        startPoint: routes.value[index].endPoint,
        endPoint: null,
        dateStart: previousRoute ? previousRoute.dateEnd : '',
        dateEnd: '',
        details: '',
        movement: true,
        marca: '',
        modello: '',
        targa: '',
      };
      routes.value.splice(index + 1, 0, newRoute);
    };

    const removeRoute = (index) => {
      routes.value.splice(index, 1);
    };

    const formData = ref({
      journeyDate: journeyDate.value,
    });

    const dataForm = ref(null);
    const formRuleData = {
      journeyDate: [
        {
          required: true,
          message: 'Seleziona una data!',
          trigger: 'blur',
        },
        {
          validator: (rule, value, callback) => {
            console.log('Validator triggered - Value:', value); // Debug
            if (!value || value === null || value == 'null') {
              callback(new Error('Inserire la data!'));
            } else {
              callback(); // Valid date
            }
          },
          trigger: 'change', // Ensures validation runs on value change
        },
      ],
    };

    const journeyFormFirst = ref(null);
    const journeyForm = ref({});
    const journeyFormLast = ref(null);
    const formRule = {
      startPoint: [
        {
          required: true,
          message: 'Seleziona punto di partenza!',
          trigger: 'blur',
        },
      ],
      endPoint: [
        {
          required: true,
          message: 'Seleziona punto di arrivo!',
          trigger: 'blur',
        },
      ],
      dateStart: [
        {
          required: true,
          message: 'Inserisci orario di partenza!',
          trigger: 'blur',
        },
      ],
      marca: [
        {
          required: true,
          message: 'Inserisci marca!',
          trigger: 'blur',
        },
      ],
      modello: [
        {
          required: true,
          message: 'Inserisci modello!',
          trigger: 'blur',
        },
      ],
      targa: [
        {
          required: true,
          message: 'Inserisci targa o telaio!',
          trigger: 'blur',
        },
      ],
    };

    const saveJourney = async () => {
      const validations = await Promise.all(
        routes.value.map((_, index) => {
          const formRef = journeyForm.value[`journeyForm-${index}`];
          if (formRef) {
            return formRef.validate();
          }
          return false;
        })
      );

      dataForm.value.validate(async (valid) => {
        if (valid) {

          journeyFormFirst.value.validate(async (validFormFirst) => {
            if (validFormFirst) {
              if (validations.every((isValid) => isValid)) {
                console.log('🚀 Tutti i form sono validi', routes.value);
              }

              journeyFormLast.value.validate(async (validFormLast) => {
                if (validFormLast) {
                  try {
                    const responsePartner =
                      await partnerAPI.getPartnerByEmployee(
                        storeLogin.employee.id
                      );

                    routes.value.push(endRoute.value);

                    const partner =
                      responsePartner.data[0].headquarters[0].partner;
                    const journeyPayload = {
                      date: formData.value.journeyDate,
                      driverId: null,
                      employee: storeLogin.employee,
                      partner: partner,

                      routes: routes.value.map((route) => {
                        const [startHour, startMinute] = route.dateStart
                          .split(':')
                          .map(Number);
                        const [endHour, endMinute] = route.dateEnd
                          .split(':')
                          .map(Number);

                        const fullDateStart = new Date(
                          formData.value.journeyDate
                        );
                        fullDateStart.setHours(startHour, startMinute, 0);

                        const fullDateEnd = new Date(
                          formData.value.journeyDate
                        );
                        fullDateEnd.setHours(endHour, endMinute, 0);

                        return {
                          startPoint: route.startPoint,
                          endPoint: route.endPoint,
                          dateStart: fullDateStart.toISOString(),
                          dateEnd: fullDateEnd.toISOString(),
                          details: route.details,
                          movement: route.movement,
                          marca: route.marca,
                          modello: route.modello,
                          targa: route.targa,
                        };
                      }),
                    };

                    let resp = await journeyAPI.addJourney(journeyPayload);

                    if (resp.status == 201 || resp.status == 200) {
                      ElMessage({
                        message: 'Percorso salvato con successo!',
                        type: 'success',
                      });
                      router.push('/calendar');
                    } else {
                      ElMessage({
                        message: 'Error!',
                        type: 'error',
                      });
                    }
                  } catch (error) {
                    console.error(
                      'Errore nel salvataggio del Journey:',
                      error.message
                    );
                  }
                }
              });
              //}
            }
          });
        }
      });
    };

    const calcDistance = async (origin, destination) => {
      let response = await journeyAPI.distanceJourney({
        startPoint: origin,
        endPoint: destination,
      });

      return response.data.min;
    };

    const calculateArrivalTime = async (route, index) => {
      if (route.startPoint && route.endPoint && route.dateStart) {
        const min = await calcDistance(
          [route.startPoint.longitude, route.startPoint.latitude],
          [route.endPoint.longitude, route.endPoint.latitude]
        );

        const startTime = new Date(`1970-01-01T${route.dateStart}:00`);
        startTime.setMinutes(startTime.getMinutes() + min);

        route.dateEnd = startTime.toTimeString().slice(0, 5);
        if (routes.value.length > 1 && index == routes.value.length - 2) {
          // caso in cui c'è più di una tappa e endRoute
          console.log("dentro 2")
          if (
            route === routes.value[index + 1] &&
            endRoute.value &&
            endRoute.value.startPoint &&
            endRoute.value.endPoint &&
            endRoute.value.dateStart !== route.dateEnd
          ) {
            endRoute.value.dateStart = route.dateEnd;

            if (endRoute.value.dateStart) {
              await calculateArrivalTime(endRoute.value, index);
            }
          }
        } else if (
          routes.value.length == 1 &&
          index == routes.value.length - 1
        ) {
          // caso in cui c'è solo la prima tappa e endRoute
          console.log("dentro 3")
          if (
            route === routes.value[index] &&
            endRoute.value &&
            endRoute.value.startPoint &&
            endRoute.value.endPoint &&
            endRoute.value.dateStart !== route.dateEnd
          ) {
            endRoute.value.dateStart = route.dateEnd;

            if (endRoute.value.dateStart) {
              await calculateArrivalTime(endRoute.value, index);
            }
          }
        }else if (
          route === routes.value[index+1] &&
          routes.value[index + 1] &&
          routes.value[index + 1].startPoint &&
          routes.value[index + 1].endPoint &&
          routes.value[index + 1].dateStart !== route.dateEnd
        ) {
          console.log("dentro 1")
          routes.value[index + 2].dateStart = route.dateEnd;

          if (routes.value[index + 1].dateStart) {
            await calculateArrivalTime(routes.value[index + 2], index);
            endRoute.value.dateStart =null
            endRoute.value.dateEnd =null
          }
        }

        calculateTotalHours(route);
      }
    };

    const totalHours = ref(0);

    const calculateTotalHours = (endRoute) => {
      //let totalMinutes = 0;
      if (endRoute.dateStart && endRoute.dateEnd) {
        const [startHour, startMinute] = endRoute.dateStart
          .split(':')
          .map(Number);
        const [endHour, endMinute] = endRoute.dateEnd.split(':').map(Number);

        // Crea oggetti dayjs con ora e minuto
        const start = dayjs().hour(startHour).minute(startMinute);
        const end = dayjs().hour(endHour).minute(endMinute);

        // Calcola la differenza in minuti
        let totalMinutes = end.diff(start, 'minute');

        routes.value.forEach((route) => {
          // Estrai le ore e i minuti dal formato "HH:mm"
          const [startHour, startMinute] = route.dateStart
            .split(':')
            .map(Number);
          const [endHour, endMinute] = route.dateEnd.split(':').map(Number);

          // Crea oggetti dayjs con ora e minuto
          const start = dayjs().hour(startHour).minute(startMinute);
          const end = dayjs().hour(endHour).minute(endMinute);

          // Calcola la differenza in minuti
          const diff = end.diff(start, 'minute');
          totalMinutes += diff;
        });

        // Calcola ore e minuti totali
        const hours = Math.floor(totalMinutes / 60); // Ore intere
        const minutes = totalMinutes % 60; // Minuti rimanenti
        totalHours.value = `${hours}h ${minutes} min`;
      }
    };

    const dateError = ref(false);

    const validateDate = async (date) => {
      const startDate = dayjs(new Date(date))
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .format('YYYY-MM-DD HH:mm:ss');
      const endDate = dayjs(startDate)
        .add(1, 'day')
        .format('YYYY-MM-DD HH:mm:ss');

      const selectedJourneys = await journeyAPI.getJourneysByDay(
        startDate,
        endDate
      );
      const partner = storeLogin.partner;
      if (selectedJourneys.data.length >= partner.num_driver) {
        dateError.value = true; // Mostra il messaggio di errore
        formData.value.journeyDate = null; // Resetta la data selezionata
      } else {
        dateError.value = false; // Nascondi il messaggio di errore
      }
    };

    const getDisabledHours = (index) => {
      const prevDateEnd = routes.value[index]?.dateEnd;
      if (!prevDateEnd) return [];

      const [endHour] = prevDateEnd.split(':').map(Number);
      return [...Array(endHour).keys()];
    };

    const getDisabledMinutes = (index, selectedHour) => {
      const prevDateEnd = routes.value[index]?.dateEnd;
      if (!prevDateEnd) return [];

      const [endHour, endMinutes] = prevDateEnd.split(':').map(Number);
      if (selectedHour === endHour) {
        return [...Array(endMinutes).keys()];
      }
      return [];
    };

    loadHeadquarters();

    return {
      //validTimes,
      headquartersCascader,
      journeyDate,
      routes,
      addRoute,
      removeRoute,
      saveJourney,
      calcDistance,
      calculateArrivalTime,
      calculateTotalHours,
      Delete,
      totalHours,
      endRoute,
      journeyForm,
      journeyFormFirst,
      journeyFormLast,
      formData,
      dataForm,
      validateDate,
      formRuleData,
      formRule,
      dateError,
      getDisabledHours,
      getDisabledMinutes,
    };
  },
  methods: {
    handleChangeStartPoint(selectedValue, index) {
      this.routes[index].startPoint = selectedValue[0];

      if (index == 0) {
        this.endRoute.endPoint = selectedValue[0];
      }
    },

    async handleChangeEndPoint(selectedValue, index) {
      this.routes[index].endPoint = selectedValue[0];
      


      if (index == this.routes.length - 1) {
        this.endRoute.startPoint = selectedValue[0];
        this.endRoute.dateStart = this.routes[index].dateEnd;
        await this.calculateArrivalTime(this.endRoute, index);
      }else{
        this.routes[index+1].startPoint = selectedValue[0];

      }
      await this.$nextTick();
      await this.calculateArrivalTime(this.routes[index], index);
    },

    validTimes(index) {
      const times = [];
      for (let hour = 9; hour < 18; hour++) {
        if (this.getDisabledHours(index).includes(hour)) continue;

        for (let minute = 0; minute < 60; minute++) {
          if (this.getDisabledMinutes(index, hour).includes(minute)) continue;

          times.push(
            `${hour.toString().padStart(2, '0')}:${minute
              .toString()
              .padStart(2, '0')}`
          );
        }
      }
      return times;
    },
  },
};
</script>

<style>
.journey-section {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.route-row {
  display: flex;
  align-items: center;
  gap: 20px;
}

.route-item {
  flex: 1; /* Ensures all items have equal width */
}

.journey-card {
  background-color: #ffffff; /* Sfondo bianco per contrasto */
  border: 1px solid #dcdcdc; /* Bordo sottile per definire la card */
  border-radius: 10px; /* Angoli arrotondati */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Ombra morbida */

  margin: 15px 0; /* Spaziatura esterna */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Effetto di transizione */
}

.journey-card:hover {
  transform: translateY(-5px); /* Leggero spostamento verso l'alto */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15), 0 3px 5px rgba(0, 0, 0, 0.1); /* Ombra più evidente */
  border-color: #4b30f1; /* Cambia il colore del bordo */
  cursor: pointer; /* Cambia il cursore su hover */
}

.route-item-description {
  flex: 4; /* Ensures all items have equal width */
}

.action-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 33px;
}

.summary-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.summary-card {
  width: 300px;
  background-color: #f9fafc;
  border: 1px solid #ebeef5;
  border-radius: 10px;
}

.summary-content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #303133;
}

.total-hours {
  margin: 0;
}

.error-message {
  margin: 0; /* Rimuove margini esterni */
  padding: 10px auto auto; /* Rimuove padding interno */
}

.background {
  background: #e8e8ed; /* Gradiente o colore */
  min-height: 100vh; /* Per coprire tutta l'altezza della finestra */
  padding: 5px; /* Padding opzionale */
  margin: -20px;
}

.centered-description-item {
  margin-top: 15px;
  display: flex;
  align-items: center; /* Allinea verticalmente */
  justify-content: flex-start; /* Opzionale, mantiene allineato a sinistra */
}

.centered-description-item .el-form-item {
  margin-bottom: 0; /* Rimuovi eventuali margini extra */
}

/* ********** Time Picker ************** */
.time-picker-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 4px;
  transition: border-color 0.2s ease;
  box-sizing: border-box;
}

.time-picker-wrapper:hover {
  border-color: #c0c4cc;
}

.time-picker-wrapper:focus-within {
  border-color: #409eff;
  box-shadow: 0 0 4px rgba(64, 158, 255, 0.4);
}

/* Icona */
.time-picker-icon {
  color: #909399;
  padding: 0 8px;
}

/* Input */
.time-picker-input-with-icon {
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
  padding: 4px 8px;
}

.time-picker-input-with-icon:focus {
  outline: none;
}
</style>
