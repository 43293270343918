<template>
  <h1>Dettagli del giorno {{ formattedDate }}</h1>
  <!-- <div class="container"> -->
  <div>
    <el-button
      @click="addNextRoute(-1)"
      title="Aggiungi nuova tratta"
      style="background-color: #4b30f1; color: #fff; border-color: #4b30f1"
    >
      Aggiungi la prima tappa
    </el-button>

    <el-card
      v-if="newRouteIndex === -1 && newRoute"
      class="journey-card"
      shadow="hover"
    >
      <h3 style="margin-bottom: 10px">Nuova Tappa</h3>
      <!-- Punto di Arrivo -->
      <div class="route-item">
        <el-form
          :inline="true"
          ref="journeyFormFirst"
          :model="newRoute"
          :rules="formRule"
        >
          <div class="route-row">
            <div class="route-item">
              <el-form-item label="Punto di Partenza" prop="startPoint">
                <el-cascader
                  v-model="newRoute.startPoint"
                  placeholder="Seleziona"
                  @change="
                    (selectedValue) => handleChangeStartPoint(selectedValue, 1)
                  "
                  :options="headquartersCascader"
                  filterable
                  clearable
                  :class="{ highlight: highlight }"
                  :props="{ value: 'point' }"
                  :emit-path="false"
                />
              </el-form-item>
            </div>

            <div class="route-item">
              <el-form-item label="Orario di Partenza" prop="dateStart">
                <!-- <el-time-picker
                  v-model="newRoute.dateStart"
                  placeholder="Seleziona orario"
                  arrow-control
                  format="HH:mm"
                  value-format="HH:mm"
                  @change="calculateArrivalTime(newRoute)"
                /> -->

                <div class="time-picker-wrapper">
                  <div class="time-picker-icon">
                    <i class="el-icon-time"></i>
                  </div>
                  <input
                    type="time"
                    class="time-picker-input-with-icon"
                    v-model="newRoute.dateStart"
                    placeholder="Seleziona orario"
                    @change="(event) => calculateArrivalTime(newRoute)"
                    list="time-options"
                  />
                  <datalist id="time-options">
                    <option
                      v-for="(time, index) in validTimes(-1)"
                      :key="index"
                      :value="time"
                    >
                      {{ time }}
                    </option>
                  </datalist>
                </div>
              </el-form-item>
            </div>

            <div class="route-item">
              <el-form-item label="Punto di Arrivo" prop="endPoint">
                <el-cascader
                  v-model="newRoute.endPoint"
                  placeholder="Seleziona"
                  @change="
                    (selectedValue) => handleChangeEndPoint(selectedValue, 1)
                  "
                  :options="headquartersCascader"
                  filterable
                  clearable
                  :class="{ highlight: highlight }"
                  :props="{ value: 'point' }"
                  :emit-path="false"
                />
              </el-form-item>
            </div>

            <div class="route-item">
              <el-form-item label="Orario di Arrivo" prop="dateEnd">
                <el-time-picker
                  v-model="newRoute.dateEnd"
                  placeholder="Calcolato automaticamente"
                  arrow-control
                  disabled
                  format="HH:mm"
                  value-format="HH:mm"
                />
              </el-form-item>
            </div>
          </div>
          <!-- <div class="route-row">
            <div class="route-item">
              <el-descriptions :column="1" border>
                <el-descriptions-item label="Dettagli della vettura">
                  <el-input
                    vnewRoutenewRoute.details"
                    placeholder="Es. Targa: XXXX, Marca: Renault, Modello: Clio"
                  />
                </el-descriptions-item>
              </el-descriptions>
            </div> -->

          <div class="route-row">
            <div class="route-item-description">
              <el-descriptions class="margin-top" :column="4" border>
                <el-descriptions-item label="Targa">
                  <el-form-item prop="targa">
                    <el-input
                      v-model="newRoute.targa"
                      placeholder="Targa o telaio"
                      class="centered-description-item"
                    />
                  </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="Marca">
                  <el-form-item prop="marca">
                    <el-input
                      v-model="newRoute.marca"
                      placeholder="Marca"
                      class="centered-description-item"
                    />
                  </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="Modello">
                  <el-form-item prop="modello">
                    <el-input
                      v-model="newRoute.modello"
                      placeholder="Modello"
                      class="centered-description-item"
                    />
                  </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="Dettagli">
                  <el-form-item prop="details">
                    <el-input
                      v-model="newRoute.details"
                      placeholder="Note particolari"
                      class="centered-description-item"
                    />
                  </el-form-item>
                </el-descriptions-item>
              </el-descriptions>
            </div>

            <div class="route-item">
              <el-switch
                v-model="newRoute.movement"
                active-text="Auto da spostare"
                inactive-text="Auto per il driver"
              />
            </div>
          </div>
        </el-form>
      </div>
    </el-card>

    <el-card
      v-if="newRouteIndex === -1 && newRouteNext"
      class="journey-card"
      shadow="hover"
    >
      <h3 style="margin-bottom: 10px">Tappa Successiva</h3>
      <!-- Punto di Arrivo -->
      <div class="route-item">
        <el-form
          :inline="true"
          ref="journeyFormNext"
          :model="newRouteNext"
          :rules="formRule"
        >
          <div class="route-row">
            <div class="route-item">
              <el-form-item label="Punto di Partenza" prop="startPoint">
                <el-input
                  v-if="newRoute.endPoint == null"
                  :value="''"
                  disabled
                />
                <el-input
                  v-else-if="newRoute.endPoint"
                  v-model="newRouteNext.startPoint"
                  :value="
                    newRoute.endPoint?.address +
                      ' ' +
                      newRoute.endPoint?.street_number +
                      ', ' +
                      newRoute.endPoint?.city || ''
                  "
                  disabled
                />
              </el-form-item>
            </div>

            <div class="route-item">
              <el-form-item label="Orario di Partenza" prop="dateStart">
                <!-- <el-time-picker
                  v-model="newRouteNext.dateStart"
                  placeholder="Seleziona orario"
                  arrow-control
                  format="HH:mm"
                  value-format="HH:mm"
                  @change="calculateArrivalTime(newRouteNext)"
                /> -->

                <div class="time-picker-wrapper">
                  <div class="time-picker-icon">
                    <i class="el-icon-time"></i>
                  </div>
                  <input
                    type="time"
                    class="time-picker-input-with-icon"
                    v-model="newRouteNext.dateStart"
                    placeholder="Seleziona orario"
                    @change="(event) => calculateArrivalTimeNext(index)"
                    list="time-options"
                  />
                  <datalist id="time-options">
                    <option
                      v-for="(time, index) in validTimes(index)"
                      :key="index"
                      :value="time"
                    >
                      {{ time }}
                    </option>
                  </datalist>
                </div>
              </el-form-item>
            </div>

            <div class="route-item">
              <el-form-item label="Punto di Arrivo" prop="endPoint">
                <el-input
                  v-if="routes[0].startPoint == null"
                  :value="''"
                  disabled
                />
                <el-input
                  v-else-if="routes[0].startPoint"
                  :value="
                    routes[0].startPoint?.address +
                      ' ' +
                      routes[0].startPoint?.street_number +
                      ', ' +
                      routes[0].startPoint?.city || ''
                  "
                  disabled
                />
              </el-form-item>
            </div>

            <div class="route-item">
              <el-form-item label="Orario di Arrivo" prop="dateEnd">
                <el-time-picker
                  v-model="newRouteNext.dateEnd"
                  placeholder="Calcolato automaticamente"
                  arrow-control
                  disabled
                  format="HH:mm"
                  value-format="HH:mm"
                />
              </el-form-item>
            </div>
          </div>
          <!--  <div class="route-row">
            <div class="route-item">
              <el-descriptions :column="1" border>
                <el-descriptions-item label="Dettagli della vettura">
                  <el-input
                    v-model="newRouteNext.details"
                    placeholder="Es. Targa: XXXX, Marca: Renault, Modello: Clio"
                  />
                </el-descriptions-item>
              </el-descriptions>
            </div> -->

          <div class="route-row">
            <div class="route-item-description">
              <el-descriptions class="margin-top" :column="4" border>
                <el-descriptions-item label="Targa">
                  <el-form-item prop="targa">
                    <el-input
                      v-model="newRouteNext.targa"
                      placeholder="Targa o telaio"
                      class="centered-description-item"
                    />
                  </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="Marca">
                  <el-form-item prop="marca">
                    <el-input
                      v-model="newRouteNext.marca"
                      placeholder="Marca"
                      class="centered-description-item"
                    />
                  </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="Modello">
                  <el-form-item prop="modello">
                    <el-input
                      v-model="newRouteNext.modello"
                      placeholder="Modello"
                      class="centered-description-item"
                    />
                  </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="Dettagli">
                  <el-form-item prop="details">
                    <el-input
                      v-model="newRouteNext.details"
                      placeholder="Note particolari"
                      class="centered-description-item"
                    />
                  </el-form-item>
                </el-descriptions-item>
              </el-descriptions>
            </div>
            <!-- Switch Movimento -->
            <div class="route-item">
              <el-switch
                v-model="newRouteNext.movement"
                active-text="Auto da spostare"
                inactive-text="Auto per il driver"
              />
            </div>

            <div class="action-buttons">
              <el-button class="custom-button" @click="confirmNewRoute(index)">
                Aggiungi le tappe
              </el-button>
              <el-button type="danger" @click="cancelRoute" circle>
                <el-icon><RemoveFilled /></el-icon>
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
    </el-card>

    <div v-if="routes.length > 0">
      <div v-for="(route, index) in routes" :key="route.id">
        <div class="route">
          <!-- Sezione Partenza -->
          <div class="route-section">
            <h3>Partenza</h3>
            <p>
              Orario:<strong>{{ formatDateTime(route.dateStart) }}</strong>
            </p>
            <p>
              Punto: <strong>{{ formatAddress(route.startPoint) }}</strong>
            </p>
          </div>

          <div class="divider"></div>

          <!-- Sezione Arrivo -->
          <div class="route-section">
            <h3>Arrivo</h3>
            <p><strong>Orario:</strong> {{ formatDateTime(route.dateEnd) }}</p>
            <p><strong>Punto:</strong> {{ formatAddress(route.endPoint) }}</p>
          </div>

          <div class="divider"></div>

          <!-- Sezione Auto -->
          <div class="route-section">
            <h3>Auto</h3>
            <p><strong>Targa:</strong> {{ route.targa }}</p>
            <p>
              <strong>Modello:</strong> {{ route.marca }} {{ route.modello }}
            </p>
          </div>

          <!-- Pulsanti Azione -->
          <div class="action-buttons">
            <el-button
              @click="addNextRoute(index)"
              circle
              title="Aggiungi nuova tratta"
              class="custom-button"
            >
              <el-icon>
                <Plus />
              </el-icon>
            </el-button>

            <el-button
              circle
              type="danger"
              title="Elimina la tratta"
              @click="confirmDeleteRoute(index)"
              :icon="Delete"
            ></el-button>
          </div>
        </div>

        <!-- <div v-if="routes.length > 0">
      <div v-for="(route, index) in routes" :key="route.id">
        <div class="route">
          <div class="route-detail">
            <p>
              <strong>
              {{ formatDateTime(route.dateStart) }}
            </strong>
            </p>
            <p>
              <strong>Partenza:</strong>
              {{ formatAddress(route.startPoint) }}
            </p>
          </div>
          <div class="route-detail">
            <p>
              <strong>
              {{ formatDateTime(route.dateEnd) }}
            </strong>
            </p>
            <p>
              <strong>Arrivo:</strong>
              {{ formatAddress(route.endPoint) }}
            </p>
          </div>

          <div class="route-detail">
            <p>
              <strong>Targa:</strong>
              {{ route.targa }}
            </p>
            <p>
              <strong>Auto:</strong>
              {{ route.marca }}
              {{ route.modello }}
            </p>
          </div>

          <div class="action-buttons">
            <el-button
              @click="addNextRoute(index)"
              circle
              title="Aggiungi nuova tratta"
              class="custom-button"
            >
              <el-icon>
                <Plus />
              </el-icon>
            </el-button>

            <el-button
              circle
              type="danger"
              title="Elimina la tratta"
              @click="confirmDeleteRoute(index)"
              :icon="Delete"
            >
            </el-button>
          </div>
        </div> -->
        <el-card
          v-if="newRouteIndex === index + 1 && newRoute"
          class="journey-card"
          shadow="hover"
        >
          <h3 style="margin-bottom: 10px">Nuova Tappa</h3>
          <!-- Punto di Arrivo -->
          <div class="route-item">
            <el-form
              :model="newRoute"
              :inline="true"
              ref="journeyFormArrayFirst"
              :rules="formRule"
            >
              <div class="route-row">
                <div class="route-item">
                  <el-form-item label="Punto di Partenza" prop="startPoint">
                    <el-input
                      v-if="routes[index].endPoint == null"
                      :value="''"
                      disabled
                    />
                    <el-input
                      v-else-if="routes[index].endPoint"
                      :value="
                        routes[index].endPoint?.address +
                          ' ' +
                          routes[index].endPoint?.street_number +
                          ', ' +
                          routes[index].endPoint?.city || ''
                      "
                      disabled
                    />
                  </el-form-item>
                </div>

                <div class="route-item">
                  <el-form-item label="Orario di Partenza" prop="dateStart">
                    <el-time-picker
                      v-model="newRoute.dateStart"
                      placeholder="Seleziona orario"
                      arrow-control
                      format="HH:mm"
                      value-format="HH:mm"
                      @change="calculateArrivalTime(newRoute)"
                      :disabled-hours="() => getDisabledHours(index)"
                      :disabled-minutes="
                        (hour) => getDisabledMinutes(index, hour)
                      "
                    />
                  </el-form-item>
                </div>

                <div class="route-item">
                  <el-form-item label="Punto di Arrivo" prop="endPoint">
                    <el-cascader
                      v-model="newRoute.endPoint"
                      placeholder="Seleziona"
                      @change="
                        (selectedValue) =>
                          handleChangeEndPoint(selectedValue, index + 1)
                      "
                      :options="headquartersCascader"
                      filterable
                      clearable
                      :class="{ highlight: highlight }"
                      :props="{ value: 'point' }"
                      :emit-path="false"
                    />
                  </el-form-item>
                </div>

                <div class="route-item">
                  <el-form-item label="Orario di Arrivo">
                    <el-time-picker
                      v-model="newRoute.dateEnd"
                      placeholder="Calcolato automaticamente"
                      arrow-control
                      disabled
                      format="HH:mm"
                      value-format="HH:mm"
                    />
                  </el-form-item>
                </div>
              </div>

              <div class="route-row">
                <div class="route-item-description">
                  <el-descriptions class="margin-top" :column="4" border>
                    <el-descriptions-item label="Targa">
                      <el-form-item prop="targa">
                        <el-input
                          v-model="newRoute.targa"
                          placeholder="Targa o telaio"
                          class="centered-description-item"
                        />
                      </el-form-item>
                    </el-descriptions-item>

                    <el-descriptions-item label="Marca">
                      <el-form-item prop="marca">
                        <el-input
                          v-model="newRoute.marca"
                          placeholder="Marca"
                          class="centered-description-item"
                        />
                      </el-form-item>
                    </el-descriptions-item>
                    <el-descriptions-item label="Modello">
                      <el-form-item prop="modello">
                        <el-input
                          v-model="newRoute.modello"
                          placeholder="Modello"
                          class="centered-description-item"
                        />
                      </el-form-item>
                    </el-descriptions-item>
                    <el-descriptions-item label="Dettagli">
                      <el-form-item prop="details">
                        <el-input
                          v-model="newRoute.details"
                          placeholder="Note particolari"
                          class="centered-description-item"
                        />
                      </el-form-item>
                    </el-descriptions-item>
                  </el-descriptions>
                </div>

                <div class="route-item">
                  <el-switch
                    v-model="newRoute.movement"
                    active-text="Auto da spostare"
                    inactive-text="Auto per il driver"
                  />
                </div>
              </div>
            </el-form>
          </div>
        </el-card>

        <el-card
          v-if="newRouteIndex === index + 1 && newRouteNext"
          class="journey-card"
          shadow="hover"
        >
          <h3 style="margin-bottom: 10px">Tappa Successiva</h3>
          <!-- Punto di Arrivo -->
          <div class="route-item">
            <el-form
              :model="newRouteNext"
              :inline="true"
              ref="journeyFormArrayNext"
              :rules="formRule"
            >
              <div class="route-row">
                <div class="route-item">
                  <el-form-item label="Punto di Partenza" prop="startPoint">
                    <el-input
                      v-if="newRoute.endPoint == null"
                      :value="''"
                      disabled
                    />
                    <el-input
                      v-else-if="newRoute.endPoint"
                      v-model="newRouteNext.startPoint"
                      :value="
                        newRoute.endPoint?.address +
                          ' ' +
                          newRoute.endPoint?.street_number +
                          ', ' +
                          newRoute.endPoint?.city || ''
                      "
                      disabled
                    />
                  </el-form-item>
                </div>

                <div class="route-item">
                  <el-form-item label="Orario di Partenza" prop="dateStart">
                    <el-time-picker
                      v-model="newRouteNext.dateStart"
                      placeholder="Seleziona orario"
                      arrow-control
                      format="HH:mm"
                      value-format="HH:mm"
                      @change="calculateArrivalTime(newRouteNext)"
                      :disabled-hours="() => getDisabledHours(index)"
                      :disabled-minutes="
                        (hour) => getDisabledMinutes(index, hour)
                      "
                    />
                  </el-form-item>
                </div>

                <div class="route-item">
                  <el-form-item label="Punto di Arrivo">
                    <el-input
                      v-if="newRoute.startPoint == null"
                      :value="''"
                      disabled
                    />
                    <el-input
                      v-else-if="newRoute.startPoint"
                      :value="
                        newRoute.startPoint?.address +
                          ' ' +
                          newRoute.startPoint?.street_number +
                          ', ' +
                          newRoute.startPoint?.city || ''
                      "
                      disabled
                    />
                  </el-form-item>
                </div>

                <div class="route-item">
                  <el-form-item label="Orario di Arrivo" prop="endPoint">
                    <el-time-picker
                      v-model="newRouteNext.dateEnd"
                      placeholder="Calcolato automaticamente"
                      arrow-control
                      disabled
                      format="HH:mm"
                      value-format="HH:mm"
                    />
                  </el-form-item>
                </div>
              </div>

              <div class="route-row">
                <div class="route-item-description">
                  <el-descriptions class="margin-top" :column="4" border>
                    <el-descriptions-item label="Targa">
                      <el-form-item prop="targa">
                        <el-input
                          v-model="newRouteNext.targa"
                          placeholder="Targa o telaio"
                          class="centered-description-item"
                        />
                      </el-form-item>
                    </el-descriptions-item>

                    <el-descriptions-item label="Marca">
                      <el-form-item prop="marca">
                        <el-input
                          v-model="newRouteNext.marca"
                          placeholder="Marca"
                          class="centered-description-item"
                        />
                      </el-form-item>
                    </el-descriptions-item>
                    <el-descriptions-item label="Modello">
                      <el-form-item prop="modello">
                        <el-input
                          v-model="newRouteNext.modello"
                          placeholder="Modello"
                          class="centered-description-item"
                        />
                      </el-form-item>
                    </el-descriptions-item>
                    <el-descriptions-item label="Dettagli">
                      <el-form-item prop="details">
                        <el-input
                          v-model="newRouteNext.details"
                          placeholder="Note particolari"
                          class="centered-description-item"
                        />
                      </el-form-item>
                    </el-descriptions-item>
                  </el-descriptions>
                </div>

                <div class="route-item">
                  <el-switch
                    v-model="newRouteNext.movement"
                    active-text="Auto da spostare"
                    inactive-text="Auto per il driver"
                  />
                </div>

                <div class="action-buttons">
                  <el-button
                    class="custom-button"
                    @click="confirmNewRoute(index)"
                  >
                    Aggiungi le tappe
                  </el-button>
                  <el-button type="danger" @click="cancelRoute" circle>
                    <el-icon><RemoveFilled /></el-icon>
                  </el-button>
                </div>
              </div>
            </el-form>
          </div>
        </el-card>
      </div>
    </div>

    <div v-else>
      <p class="no-data">Nessun route trovato per questa data.</p>
    </div>
  </div>

  <!-- Pulsante Aggiorna -->
  <div class="button-container">
    <el-button
      size="large"
      icon="el-icon-refresh"
      style="background-color: #4b30f1; color: #fff; border-color: #4b30f1"
      @click="modifyJourney"
      round
    >
      <el-icon style="margin-right: 5px"><Refresh /></el-icon>
      Aggiorna il Journey
    </el-button>
  </div>

  <!-- Riepilogo Totale Ore -->
  <div v-if="routes.length > 0" class="summary-container">
    <el-card class="summary-card" shadow="hover">
      <div class="summary-content">
        <el-icon style="margin-right: 10px; color: #409eff">
          <Timer />
        </el-icon>
        <p class="total-hours"><strong>Totale Ore:</strong> {{ totalHours }}</p>
      </div>
    </el-card>
  </div>
</template>
<script>
import { ref, onMounted, toRaw } from 'vue';
import { useRoute } from 'vue-router';
import journeyAPI from '../services/journeyAPI';
import dayjs from 'dayjs';
import bookingAPI from '../services/bookingAPI';
import { isLoggedStore } from '../store/logged';
import { ElMessage, ElMessageBox } from 'element-plus';
import { useRouter } from 'vue-router';
import { Delete, Plus } from '@element-plus/icons-vue';
export default {
  setup() {
    const route = useRoute();
    const selectedDate = route.params.date;
    const id = route.params.id;
    const formattedDate = dayjs(selectedDate).format('DD/MM/YYYY');
    const journey = ref([]);
    const routes = ref([]);
    const totalHours = ref(0);
    const router = useRouter();

    const getJourneysForDate = async () => {
      try {
        /*
        const today = dayjs(new Date(selectedDate))
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .format('YYYY-MM-DD HH:mm:ss');
        const tomorrow = dayjs(today)
          .add(1, 'day')
          .format('YYYY-MM-DD HH:mm:ss');
        const response = await journeyAPI.getJourneysByDay(today, tomorrow); */
        const response = await journeyAPI.getJourneysById(id);
        journey.value = response.data[0];
        routes.value = response.data[0].routes;
        calculateTotalHours();
      } catch (error) {
        console.error('Errore nel recupero dei journey:', error.message);
      }
    };

    const calculateTotalHours = () => {
      let totalMinutes = 0;

      routes.value.forEach((route) => {
        const start = dayjs(route.dateStart);
        const end = dayjs(route.dateEnd);
        const diff = end.diff(start, 'minute');
        totalMinutes += diff;
      });

      const hours = Math.floor(totalMinutes / 60); // Ore intere
      const minutes = totalMinutes % 60; // Minuti rimanenti

      totalHours.value = `${hours}h ${minutes} min`;
    };
    const formatDateTime = (dateTime) => {
      return dayjs(dateTime).format('HH:mm');
    };

    onMounted(() => {
      getJourneysForDate();
    });

    const formatAddress = (point) => {
      if (!point) return 'Non disponibile';
      return `${point.address} ${point.street_number}, ${point.city}`;
    };

    const storeLogin = isLoggedStore();
    const headquartersCascader = ref([]);
    const headquarters = ref([]);

    const loadHeadquarters = async () => {
      try {
        const response = await bookingAPI.getHeadquartersByEmployee(
          storeLogin.id
        );
        response.data[0].headquarters.forEach((headquarter) => {
          headquartersCascader.value.push({
            value: headquarter.id,
            label: `${headquarter.address} ${headquarter.street_number} ${headquarter.city}`,
            point: {
              address: headquarter.address,
              street_number: headquarter.street_number,
              cap: headquarter.cap,
              city: headquarter.city,
              province: headquarter.province,
              latitude: headquarter.latitude,
              longitude: headquarter.longitude,
            },
          });
          headquarters.value.push(headquarter);
        });
      } catch (error) {
        console.error(
          'Errore nel caricamento degli headquarters:',
          error.message
        );
      }
    };

    let newRoute = ref(null);
    let newRouteNext = ref(null);
    const newRouteIndex = ref(0);

    const addNextRoute = (index) => {
      loadHeadquarters();
      const previousRoute = index < 0 ? null : routes.value[index];
      newRouteIndex.value = index < 0 ? -1 : index + 1;
      newRoute.value = {
        dateStart: previousRoute
          ? dayjs(previousRoute.dateEnd).format('HH:mm')
          : null,
        dateEnd: '',
        startPoint: previousRoute
          ? {
              address: toRaw(previousRoute.endPoint).address,
              street_number: toRaw(previousRoute.endPoint).street_number,
              city: toRaw(previousRoute.endPoint).city,
              province: toRaw(previousRoute.endPoint).province,
              latitude: toRaw(previousRoute.endPoint).latitude,
              cap: toRaw(previousRoute.endPoint).cap,
              longitude: toRaw(previousRoute.endPoint).longitude,
              latituide: toRaw(previousRoute.endPoint).latituide,
            }
          : null,
        endPoint: null,
        details: '',
        targa: '',
        marca: '',
        modello: '',
        movement: false,
      };
      /* newRouteNext.value = {
        dateStart: newRoute.value.dateEnd,
        dateEnd: '',
        startPoint: toRaw(newRoute.value.endPoint),
        endPoint:
          index > 0
            ? toRaw(newRoute.value.startPoint)
            : routes.value[0].startPoint,
        details: '',
        targa: '',
        marca: '',
        modello: '',
        movement: false,
      }; */

      newRouteNext.value = {
        dateStart: newRoute.value.dateEnd,
        dateEnd: '',
        startPoint: toRaw(newRoute.value.endPoint),
        endPoint:
          index < 0
            ? routes.value[0].startPoint
            : toRaw(newRoute.value.startPoint),
        details: '',
        targa: '',
        marca: '',
        modello: '',
        movement: false,
      };
    };

    const cancelRoute = () => {
      newRoute.value = null;
      newRouteIndex.value = null;
    };

    const calcDistance = async (origin, destination) => {
      let response = await journeyAPI.distanceJourney({
        startPoint: origin,
        endPoint: destination,
      });

      return response.data.min;
    };

    const calculateArrivalTime = async (route) => {
      //console.log("🚀 ~ calculateArrivalTime ~ route:", route)
      if (route.startPoint && route.endPoint && route.dateStart) {
        const min = await calcDistance(
          [route.startPoint.longitude, route.startPoint.latitude],
          [route.endPoint.longitude, route.endPoint.latitude]
        );

        const startTime = new Date(`1970-01-01T${route.dateStart}:00`);
        startTime.setMinutes(startTime.getMinutes() + min);

        route.dateEnd = startTime.toTimeString().slice(0, 5);
        calculateTotalHours();

        if (
          route === newRoute.value &&
          newRouteNext.value &&
          newRouteNext.value.startPoint &&
          newRouteNext.value.endPoint &&
          newRouteNext.value.dateStart !== route.dateEnd
        ) {
          newRouteNext.value.dateStart = route.dateEnd;

          if (newRouteNext.value.dateStart) {
            await calculateArrivalTime(newRouteNext.value);
          }
        }
      }
    };

    const calculateArrivalTimeNext = async (index) => {
      console.log("🚀 ~ calculateArrivalTimeNext ~ index:", index)
      let route = newRouteNext.value;
      
      if (route.startPoint && route.endPoint && route.dateStart) {
        const min = await calcDistance(
          [route.startPoint.longitude, route.startPoint.latitude],
          [route.endPoint.longitude, route.endPoint.latitude]
        );

        const startTime = new Date(`1970-01-01T${route.dateStart}:00`);
        startTime.setMinutes(startTime.getMinutes() + min);

        route.dateEnd = startTime.toTimeString().slice(0, 5);
        calculateTotalHours();

        if (
          route === newRoute.value &&
          newRouteNext.value &&
          newRouteNext.value.startPoint &&
          newRouteNext.value.endPoint &&
          newRouteNext.value.dateStart !== route.dateEnd
        ) {
          newRouteNext.value.dateStart = route.dateEnd;

          if (newRouteNext.value.dateStart) {
            await calculateArrivalTime(newRouteNext.value);
          }
        }
      }

    };
    const modifyJourney = async () => {
      try {
        let response = await journeyAPI.modifyJourney(
          journey.value.id,
          journey.value
        );
        if (response.status === 201 || response.status === 200) {
          router.push('/detail-journey/' + selectedDate);
          ElMessage({
            message: 'Journey aggiornato con successo!',
            type: 'success',
          });
        } else {
          ElMessage({
            message: 'Error!',
            type: 'error',
          });
        }
      } catch (error) {
        ElMessage({
          message: 'Error!',
          type: 'error',
        });
        console.log(error);
      }
    };

    /* const getDisabledHours = (index) => {
      const prevDateEnd = routes.value[index]?.dateEnd;
      if (!prevDateEnd) return [];

      const endHour = dayjs(prevDateEnd).hour();

      return [...Array(endHour).keys()];
    };

    const getDisabledMinutes = (index, selectedHour) => {
      const prevDateEnd = routes.value[index]?.dateEnd;
      if (!prevDateEnd) return [];

      //const [endHour, endMinutes] = prevDateEnd.split(':').map(Number);
      const endHour = dayjs(prevDateEnd).hour();
      const endMinutes = dayjs(prevDateEnd).minute();
      if (selectedHour === endHour) {
        return [...Array(endMinutes).keys()];
      }
      return [];
    }; */

    const journeyFormFirst = ref(null);
    const journeyFormNext = ref(null);

    const journeyFormArrayFirst = ref(null);
    const journeyFormArrayNext = ref(null);

    const formRule = {
      startPoint: [
        {
          required: true,
          message: 'Seleziona punto di partenza!',
          trigger: 'blur',
        },
      ],
      endPoint: [
        {
          required: true,
          message: 'Seleziona punto di arrivo!',
          trigger: 'blur',
        },
      ],
      dateStart: [
        {
          required: true,
          message: 'Inserisci orario di partenza!',
          trigger: 'blur',
        },
      ],
      marca: [
        {
          required: true,
          message: 'Inserisci marca!',
          trigger: 'blur',
        },
      ],
      modello: [
        {
          required: true,
          message: 'Inserisci modello!',
          trigger: 'blur',
        },
      ],
      targa: [
        {
          required: true,
          message: 'Inserisci targa o telaio!',
          trigger: 'blur',
        },
      ],
    };
    let addNewRoutes = ref([]);

    const confirmNewRoute = async (index) => {
      let form = journeyFormArrayFirst.value
        ? journeyFormArrayFirst.value[0]
        : journeyFormFirst.value;
      let formNext = journeyFormArrayNext.value
        ? journeyFormArrayNext.value[0]
        : journeyFormNext.value;

      form.validate(async (validFormFirst) => {
        if (validFormFirst) {
          formNext.validate(async (validFormNext) => {
            console.log(
              '🚀 ~ formNext.validate ~ validFormNext:',
              validFormNext
            );
            if (validFormNext) {
              const [startHour, startMinute] = newRoute.value.dateStart
                .split(':')
                .map(Number);
              const [endHour, endMinute] = newRoute.value.dateEnd
                .split(':')
                .map(Number);

              const fullDateStart = dayjs(selectedDate)
                .hour(startHour)
                .minute(startMinute);
              newRoute.value.dateStart = dayjs(fullDateStart).format(
                'YYYY-MM-DD HH:mm:ss'
              );

              const fullDateEnd = dayjs(selectedDate)
                .hour(endHour)
                .minute(endMinute);
              newRoute.value.dateEnd = dayjs(fullDateEnd).format(
                'YYYY-MM-DD HH:mm:ss'
              );

              routes.value.splice(index + 1, 0, newRoute.value);

              const [startHourNext, startMinuteNext] =
                newRouteNext.value.dateStart.split(':').map(Number);
              const [endHourNext, endMinuteNext] = newRouteNext.value.dateEnd
                .split(':')
                .map(Number);

              const fullDateStartNext = dayjs(selectedDate)
                .hour(startHourNext)
                .minute(startMinuteNext);
              newRouteNext.value.dateStart = dayjs(fullDateStartNext).format(
                'YYYY-MM-DD HH:mm:ss'
              );

              const fullDateEndNext = dayjs(selectedDate)
                .hour(endHourNext)
                .minute(endMinuteNext);
              newRouteNext.value.dateEnd = dayjs(fullDateEndNext).format(
                'YYYY-MM-DD HH:mm:ss'
              );

              routes.value.splice(index + 2, 0, newRouteNext.value);

              addNewRoutes.value.push(newRoute.value);
              addNewRoutes.value.push(newRouteNext.value);

              newRoute.value = null;
              newRouteNext.value = null;

              calculateTotalHours();
            }
          });
        }
      });
    };

    const deleteRoute = async (routeId) => {
      try {
        await journeyAPI.deleteJourney(routeId); // Chiama l'API per eliminare il journey
        ElMessage({
          type: 'success',
          message: 'Tratta eliminata con successo.',
        });
        calculateTotalHours();
        routes.value = routes.value.filter((route) => route.id !== routeId);
      } catch (error) {
        console.error("Errore durante l'eliminazione della tratta:", error);
        ElMessage({
          type: 'error',
          message: "Errore durante l'eliminazione della tratta.",
        });
      }
    };

    const confirmDeleteRoute = async (index) => {
      const routeToDelete = routes.value[index];
      const nextRouteToDelete = routes.value[index + 1];

      if (!routeToDelete) {
        ElMessage({
          type: 'info',
          message: 'La tratta selezionata non esiste.',
        });
        return;
      }

      ElMessageBox.confirm(
        'Sei sicuro di voler eliminare questa tratta e quella successiva?',
        'Conferma eliminazione',
        {
          confirmButtonText: 'Conferma',
          cancelButtonText: 'Annulla',
          type: 'warning',
        }
      )
        .then(async () => {
          // Elimina la tratta selezionata
          await deleteRoute(routeToDelete.id);

          if (nextRouteToDelete) {
            await deleteRoute(nextRouteToDelete.id);
          }
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: 'Eliminazione annullata.',
          });
        });
    };

    const getDisabledHours = (index) => {
      const prevDateEnd = routes.value[index]?.dateEnd;
      console.log(
        '🚀 ~ getDisabledHours ~ prevDateEnd:',
        dayjs(prevDateEnd).hour()
      );
      if (!prevDateEnd) return [];

      const [endHour] = dayjs(prevDateEnd).hour();
      console.log('🚀 ~ getDisabledHours ~ endHour:', endHour);
      return [...Array(endHour).keys()];
      //return [];
    };

    const getDisabledMinutes = (index, selectedHour) => {
      const prevDateEnd = routes.value[index]?.dateEnd;
      if (!prevDateEnd) return [];

      const [endHour] = dayjs(prevDateEnd).hour();
      const [endMinutes] = dayjs(prevDateEnd).minute();
      if (selectedHour === endHour) {
        return [...Array(endMinutes).keys()];
      }
      return [];
    };

    return {
      confirmDeleteRoute,
      formattedDate,
      journey,
      routes,
      addNextRoute,
      formatDateTime,
      formatAddress,
      totalHours,
      newRoute,
      cancelRoute,
      confirmNewRoute,
      headquartersCascader,
      headquarters,
      calculateArrivalTime,
      calculateArrivalTimeNext,
      modifyJourney,
      newRouteIndex,
      newRouteNext,
      Delete,
      Plus,
      journeyFormFirst,
      journeyFormNext,
      journeyFormArrayFirst,
      journeyFormArrayNext,
      formRule,
      getDisabledHours,
      getDisabledMinutes,
    };
  },
  methods: {
    handleChangeStartPoint(selectedValue, index) {
      console.log('🚀 ~ handleChangeStartPoint ~ index:', index);

      this.newRoute.startPoint = selectedValue[0];
    },
    async handleChangeEndPoint(selectedValue, index) {
      console.log('🚀 ~ handleChangeEndPoint ~ index:', index);

      this.newRoute.endPoint = selectedValue[0];
      await this.calculateArrivalTime(this.newRoute);
      this.newRouteNext.startPoint = selectedValue[0];
      this.newRouteNext.dateStart = this.newRoute.dateEnd;
      await this.calculateArrivalTime(this.newRouteNext);
    },
    validTimes(index) {
      const times = [];
      for (let hour = 9; hour < 18; hour++) {
        if (this.getDisabledHours(index).includes(hour)) continue;

        for (let minute = 0; minute < 60; minute++) {
          if (this.getDisabledMinutes(index, hour).includes(minute)) continue;

          times.push(
            `${hour.toString().padStart(2, '0')}:${minute
              .toString()
              .padStart(2, '0')}`
          );
        }
      }
      return times;
    },
  },
};
</script>

<style scoped>
.custom-button {
  background-color: #4b30f1;
  border-color: #4b30f1;
  color: #fff;
  transition: all 0.3s ease;
}

.custom-button:hover {
  background-color: #3622b8; /* Colore più scuro al passaggio del mouse */
  border-color: #3622b8;
}

.container {
  padding: 20px;
  background-color: #f9f9f9;
}

h1 {
  text-align: center;
  /*color: #4b30f1;*/
  margin-bottom: 20px;
}
h3 {
  margin: 0 0 10px;
  font-size: 16px;
  text-transform: uppercase;
  color: #333;
  border-bottom: 1px solid #4b30f1;
  display: inline-block;
}

p {
  margin: 5px 0;
  font-size: 17px;
  color: #3d3c3c;
}

.route {
  background-color: #e1ff0090;
  /*border: 2px solid #4b30f1;*/
  border-radius: 8px;
  padding: 50px;
  margin: 20px auto auto; /*Centra il rettangolo orizzontalmente */
  max-width: 1200px; /* Estensione più ampia dei rettangoli */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.route-detail p {
  margin: 5px 20px;
  color: #333;
  justify-content: center;
  align-items: center;
}

.route-section {
  flex: 1;
  min-width: 200px;
}

.divider {
  width: 2px;
  background-color: #4b30f1;
  height: auto;
  align-self: stretch;
  margin: 0 10px;
}
.no-data {
  text-align: center;
  font-size: 18px;
  color: #777;
}

.custom-button {
  background-color: #6650f5; /* Cambia il colore di sfondo */
  border-color: #6650f5; /* Cambia il colore del bordo */
  color: #fff; /* Cambia il colore del testo */
}

.custom-button:hover {
  background-color: #4b30f1; /* Colore più scuro al passaggio del mouse */
  border-color: #4b30f1;
}

.button-container {
  text-align: center;

  margin: 20px 0;
}

.summary-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.summary-card {
  width: 300px;
  background-color: #f9fafc;
  border: 1px solid #ebeef5;
  border-radius: 10px;
}

.summary-content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #303133;
}

.total-hours {
  margin: 0;
}

.journey-card {
  background-color: #ffffff; /* Sfondo bianco per contrasto */
  border: 1px solid #dcdcdc; /* Bordo sottile per definire la card */
  border-radius: 10px; /* Angoli arrotondati */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Ombra morbida */

  margin: 15px 0; /* Spaziatura esterna */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Effetto di transizione */
}

.journey-card:hover {
  transform: translateY(-5px); /* Leggero spostamento verso l'alto */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15), 0 3px 5px rgba(0, 0, 0, 0.1); /* Ombra più evidente */
  border-color: #4b30f1; /* Cambia il colore del bordo */
  cursor: pointer; /* Cambia il cursore su hover */
}
</style>
