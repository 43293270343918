import API from './API';


export default {

    addJourney(journey){
        return API.post(`/journey` , journey);
      },  
    
    modifyJourney(journeyId, journey){
        return API.patch(`/journey/` + journeyId, journey);
      },  

    deleteJourney(routeId){
        return API.delete(`/route/` + routeId);
      },  


      
    getJourneys(partnerId){
      return API.get(`/journey?filter=partner.id||$eq||${partnerId}&sort=routes.dateStart,ASC`,{
        params: {
             join: [
              'partner',
              'routes',
              'routes.startPoint',
              'routes.endPoint',
            ], 
          },
        });
    }, 

    getJourneysByDay(/* partnerId,  */currentDate, tomorrowDate){
      return API.get(`/journey?filter=date||$gte||${currentDate}&filter=date||$lte||${tomorrowDate}&sort=routes.dateStart,ASC`,{
         params: {
             join: [
              'routes',
              'routes.startPoint',
              'routes.endPoint',
            ], 
          }, 
        });
    }, 

    
    getJourneysById(id){
      return API.get(`/journey?filter=id||$eq||${id}&sort=routes.dateStart,ASC`,{
         params: {
             join: [
              'routes',
              'routes.startPoint',
              'routes.endPoint',
            ], 
          }, 
        });
    }, 

    distanceJourney(data){
        return API.post(`/journey/distance` , data);
      },  
      
}