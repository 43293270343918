<template>
  <el-config-provider :locale="locale">
    <div class="calendar-container">
     <div class="data-picker">
      <el-date-picker
        v-model="selectedMonth"
        type="month"
        :format="monthFormat"
        placeholder="Seleziona mese e anno"
        :clearable="false"
        @change="onMonthChange"
      />
    </div> 
      <el-calendar v-model="selectedDate" :default-value="selectedMonth">
        <template #date-cell="{ data }">
        
            <div class="day-cell"
            :class="{
              'journey-day': isJourneyDay(data.day),
              'is-selected': data.isSelected,
            }"

             @click="openDetailsPage(data.day, null)">
              <div class="day-number">{{ formatDate(data.day) }}</div>
              <div
                v-if="getJourneysForDay(data.day).length"
                class="journeys-container"
              >
                <div
                  v-for="(journey, index) in getJourneysForDay(data.day)"
                  :key="index"
                  class="journey-rectangle"
                >
                  <div
                    class="day-cell"
                    @click="openDetailsPage(data.day, journey.id)"
                  >
                    {{ journey.title }}
                  </div>
                </div>
              </div>
            </div>
        </template>
      </el-calendar>
    </div>
  </el-config-provider>
</template>

<script>
import { ref } from 'vue';
import journeyAPI from '../services/journeyAPI';
import { isLoggedStore } from '../store/logged';
import { ElCalendar, ElConfigProvider } from 'element-plus';
import { useRouter } from 'vue-router';
import 'element-plus/dist/index.css';
import it from 'element-plus/es/locale/lang/it'; // Importa la lingua italiana
import dayjs from 'dayjs';
import 'dayjs/locale/it';
dayjs.locale('it');
export default {
  name: 'JourneyCalendar',
  components: {
    ElCalendar,
    ElConfigProvider,
  },
  setup() {
    //dayjs.locale('it');
    const storeLogin = isLoggedStore();
    const selectedDate = ref(new Date());
    const selectedMonth = ref(null); //ref(dayjs().toDate());
    const router = useRouter();
    const journeys = ref([]);
    const locale = it;

    const monthFormat = 'MMMM YYYY';

    /* const getJourneys = async () => {
      try {
        const response = await journeyAPI.getJourneys(storeLogin.partner.id);
        journeys.value = response.data.map((journey) =>
          dayjs(journey.date).format('YYYY-MM-DD')
        );
      } catch (error) {
        console.error('Errore nel recuperare i journey:', error.message);
      }
    }; */

    const getJourneys = async () => {
      const response = await journeyAPI.getJourneys(storeLogin.partner.id);
      journeys.value = response.data.map((journey) => ({
        id: journey.id,
        date: dayjs(journey.date).format('YYYY-MM-DD'),
        title: journey.routes[0]?.dateStart
          ? dayjs(journey.routes[0].dateStart).format('HH:mm')
          : 'No Route',
      }));
    };

    // Controlla se un giorno è un giorno con journey
    const isJourneyDay = (day) => {
      for (let j of journeys.value) {
        if (day === j) {
          return true; // Match trovato
        }
      }
      return false; // Nessun match trovato
    };

    const getJourneysForDay = (day) => {
      return journeys.value.filter((journey) => journey.date === day);
    };

    const formatDate = (day) => {
      return dayjs(day).format('DD');
    };

    getJourneys();

    const openDetailsPage = (day, id) => {
      const formattedDay = dayjs(day).format('YYYY-MM-DD');
      const dayJourneys = getJourneysForDay(formattedDay);
      console.log("🚀 ~ openDetailsPage ~ dayJourneys:", dayJourneys)

      if (dayJourneys.length > 0) {
        router.push({
          name: 'DetailsJourneyPage',
          params: { date: formattedDay, id },
        });
      } else {
        console.log("dentro")
        router.push({ name: 'JourneyPage', params: { date: formattedDay } });
      }
    };

    const onMonthChange = (date) => {
      selectedMonth.value = dayjs(date).locale('it').format('MMMM YYYY');
      selectedDate.value = dayjs(date).startOf('month').toDate();
    };

    return {
      selectedDate,
      journeys,
      isJourneyDay,
      formatDate,
      openDetailsPage,
      locale,
      onMonthChange,
      selectedMonth,
      monthFormat,
      getJourneysForDay,
    };
  },
};
</script>
<style>
/* Stile della cella del giorno */
.day-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box; /* Include padding e bordo nella dimensione totale */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Effetti di transizione */
}

/* Hover su tutti i giorni */
.day-cell:hover {
  transform: scale(1.03); /* Leggero ingrandimento */
}

.day-number {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

.journeys-container {
  display: flex;
  flex-direction: column; /* Disposizione verticale */
  gap: 5px; /* Spaziatura tra i rettangoli */
  width: 100%; /* Larghezza completa */
  align-items: center; /* Centrare i rettangoli */
}

/* Stile del rettangolo del journey */
.journey-rectangle {
  background-color: #8e7fe9;
  border: 2px solid #4b30f1;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px; /* Spazi interni */
  text-align: center;
  width: 80%; /* Rettangoli meno larghi della cella */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

/* Hover sul rettangolo del journey */
.journey-rectangle:hover {
  background-color: #4b30f1;
  cursor: pointer;
}

.journey-day2 {
  background-color: #8e7fe9;
  border: 2px solid #4b30f1; /* Bordo colorato */
  border-radius: 10px; /* Angoli arrotondati */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombra delicata */
  color: rgb(236, 233, 255); /* Testo in tinta con il bordo */
  font-weight: bold; /* Testo in grassetto */
  display: flex; /* Allineamento del contenuto */
  justify-content: center;
  align-items: center;
  height: 100%; /* Occupa l'intera altezza della cella */
  transition: all 0.3s ease; /* Effetto di transizione */
}

.journey-day2:hover {
  background-color: #8c52ff; /* Colore più scuro al passaggio del mouse */
  border-color: #4b30f1;
  cursor: pointer;
}
.el-date-picker {
  margin-bottom: 20px;
  max-width: 300px;
  width: 100%;
}

.is-selected {
  border: 2px solid #ffffff00;
}

.data-picker{
  margin-bottom: 6px;
}
</style>
